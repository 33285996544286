import Navbar from '../componets/Navbar'
import Footer from '../componets/Footer'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Box,
  HStack,
  Radio,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Select,
  useToast,
  FormHelperText,
  Text,
  Grid,
  Image,
  GridItem

} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { signupUser } from '../redux/userSignup/userSignup.action';
import { ISACTIVE } from '../redux/userSignup/userSignup.type';
import GroupLogo from '../assets/Group 26586.png';
import { SimpleGrid } from '@chakra-ui/react'
import Group36873 from "../assets/Group 36873.png"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const Payment = () => {
  const personid = window.location.pathname.split('/').slice(-1)[0];
  const { isAuth, isError, isErrorMsg, isActive, isLoading } = useSelector(store => store.userSignup);
  const dispatch = useDispatch();
  const toast = useToast();
  const apiBase = process.env.REACT_APP_MAIN_URL;
  const [successData, setSuccessData] = useState([]);

  useEffect(() => {
    const apiData = {
      ResponseId: personid
    };
    axios
      .post(`${apiBase}api/payment/updatePaymentStatus`, apiData)
      .then((response) => {
        setSuccessData(response.data.data);
        handlePaymentStatus(response.data.data.payment_status);
      })
      .catch((error) => {
        // Handle the error if needed
      });
  }, []);

  const handlePaymentStatus = (paymentStatus) => {
    debugger;
    if (paymentStatus !== undefined || paymentStatus !== null || paymentStatus !== '') {
      if (paymentStatus === 'WAITING-BANK-RESPONSE' || paymentStatus === "TRANSACTION-INCOMPLETE") {
        window.location.href = `/payment/Pending/${personid}`;
      } else if (paymentStatus === 'SUCCESS') {
        // Navigate to "Success" page
        window.location.href = `/payment/Success/${personid}`;
      } else if (paymentStatus === 'FAILED') {
        // Navigate to "Failed" page
        window.location.href = `/payment/Failed/${personid}`;
      } else {
        // Handle other cases if needed
      }
    } else {
      console.log("paymentStatus not found");
    }


    return (
      <></>
    )
  }
}
  export default Payment