/* eslint-disable no-sequences */
import { useState, useEffect,useRef  } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  RadioGroup,
  HStack,
  Radio,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
  FormHelperText,
  Text,
  Checkbox,
  Modal,
  Textarea,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Box,
  useRadio,
  chakra,
  Image,
  useRadioGroup,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, useLocation } from "react-router-dom";
import { signupUser } from "../redux/userSignup/userSignup.action";
import { ISACTIVE } from "../redux/userSignup/userSignup.type";
import GroupLogo from "../assets/Group 26586.png";

import { SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { apiBase } from "../config.js";
import IMG from "../assets/288635995.jpg";
import processed from "../assets/processed-8d19072e-d3d5-48bc-835e-698f3b748916_17IKQrNH.jpeg";
import ContactFooter from "../assets/processed-04744cf0-64a7-4c38-9b71-26fb6ce9fbf5_9sMmX2hs.jpeg";
// import { Select as SerchableSelect } from "react-select";
// import Select from "react-select";
import ReactSelect from "react-select";
import { Select as ChakraSelect } from "@chakra-ui/react";
import raas from "../assets/Raas.png";


const customStyles = {
  control: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

const Signup = () => {
  const location = useLocation();
  const isAdmin = location?.state?.Isadmin ?? 0;
  const AdminId = location?.state?.AdminId ?? 'System';

  const { isAuth, isError, isErrorMsg, isActive } = useSelector(
    (store) => store.userSignup
  );
  const dispatch = useDispatch();
  const toast = useToast();
  const apiBase = process.env.REACT_APP_MAIN_URL;

  const Navigate = useNavigate();
  const [hotelData, setHotelData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hoteslValue, setHoteslValue] = useState([]);
  const [clubName, setClubName] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [calculateCharges, setcalculateCharges] = useState([]);
  const [isHotelSelected, setIsHotelSelected] = useState(false);
  const [adultData, setadultData] = useState([{}]);
  const [isViewModalOpen, setViewModalOpen] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(null);
  const [isChildSelected, setIsChildSelected] = useState(false);
  const [selectedClub, setSelectedClub] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const [genderValue, setGenderValue] = useState(null);
  const [selectedHotelValue, setSelectedHotelValue] = useState(null);
  const [selectedFoodValue, setSelectedFoodValue] = useState(null);
  const [comingConference, setComingConference] = useState(null);
  const [childRoomCharges, setChildRoomCharges] = useState(null);
  const [totalCharge, setTotalCharge] = useState(null);
  const [displaytotalCharge, setDisplaytotalCharge] = useState(null);
  const [formattedTotalCharge, setFormattedTotalCharge] = useState(null);
  const [formattedConvinienceCharge, setFormattedConvinienceCharge] = useState(null);
  const [formattedGrandTotal, setFormattedGrandTotal] = useState(null);
  const [isMocktail, setisMocktail] = useState(null);


  const paymentModeRef = useRef(null);


  // console.log("787878787", isAdmin);

  const initialFormState = {
    member_firstname: "",
    member_surname: "",
    gender: "",
    mobile_number: "",
    email: "",
    rotary_club_id: "",
    hotel_id: "",
    room_id: "",
    food_preference: "",
    travel_mode: "",
    no_of_occupant: "",
    with_room: "1",
    adult_1: "",
    adult_1_gender: "",
    isChild_1: "",
    no_of_children: "1",
    adult_2: "",
    adult_2_gender: "",
    isChild_2: "",
    child_room_charges: "0",
    isChildAdd: "0",
    room_charges: "",
    registration_charges: "",
    total_payable_amount: "",
    payment_mode: "",
    payment_note: "",
    isAdmin: "",
    isMocktail: ""
  };

  // RedioBoxes--------------------------

  // 1. Create a component that consumes the `useRadio` hook
  function RadioCard({ children, ...props }) {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
      <Box as="label">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          _checked={{
            bg: "rgba(21, 71, 142, 1);",
            color: "white",
            borderColor: "teal.600",
          }}
          _focus={{
            boxShadow: "outline",
          }}
          px={5}
          py={3}
        >
          {children} {props.isChecked && "🗸"}
        </Box>
      </Box>
    );
  }

  // Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
  function RedioBox() {
    const options = ["Male", "Female"];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "gender",
      defaultValue: genderValue,
      onChange: (value) => {
        setGenderValue(value);
        setForm((prevForm) => ({
          ...prevForm,
          gender: value,
          adult_2_gender: value,
        }));

        if (value === "Male") {
          setForm((prevForm) => ({
            ...prevForm,
            gender: value,
            adult_1_gender: "Female",
          }));
        } else {
          setForm((prevForm) => ({
            ...prevForm,
            gender: value,
            adult_1_gender: "Male",
          }));
        }
      },
    });

    const group = getRootProps();

    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
    );
  }

  function RedioBox1() {
    const options = [
      { HotelId: "1", HotelName: "Treat Resort" },
      { HotelId: "2", HotelName: "Raas Resort" },
    ];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "hotel",
      defaultValue: selectedHotelValue,
      onChange: (value) => {
        setSelectedHotelValue(value);
        setForm((prevForm) => ({
          ...prevForm,
          hotel_id: value,
        }));
      },
    });

    const group = getRootProps();

    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value: value.HotelId });
          return (
            <RadioCard key={value.HotelId} name={value.HotelName} {...radio}>
              {value.HotelName}
            </RadioCard>
          );
        })}
      </HStack>
    );
  }

  function RedioBoxForMocktail() {
    const options = ["Yes", "No"];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "isMocktail",
      defaultValue: isMocktail,
      onChange: (value) => {
        console.log("Value selected:", value);
        setisMocktail(value);
        setForm((prevForm) => ({
          ...prevForm,
          isMocktail: value === "Yes" ? "1" : "0",
        }));
      },
    });

    const group = getRootProps();

    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio} isChecked={radio.isChecked}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
    );
  }

  function RedioBox2() {
    const options = ["Regular", "Jain"];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "Food_Preference",
      defaultValue: selectedFoodValue,
      onChange: (value) => {
        setSelectedFoodValue(value);
        setForm((prevForm) => ({
          ...prevForm,
          food_preference: value,
        }));
      },
    });

    const group = getRootProps();

    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value });
          return (
            <RadioCard key={value} {...radio} isChecked={radio.isChecked}>
              {value}
            </RadioCard>
          );
        })}
      </HStack>
    );
  }

  function RedioBox3() {
    const options = [
      { id: "ByRoad", name: "By Road" },
      { id: "ByTrain", name: "By Train" },
    ];

    const { getRootProps, getRadioProps } = useRadioGroup({
      name: "coming_the_conference",
      defaultValue: comingConference,
      onChange: (value) => {
        setComingConference(value);
        setForm((prevForm) => ({
          ...prevForm,
          travel_mode: value,
        }));
      },
    });

    const group = getRootProps();

    return (
      <HStack {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value: value.id });
          return (
            <RadioCard key={value.id} name={value.name} {...radio}>
              {value.name}
            </RadioCard>
          );
        })}
      </HStack>
    );
  }

  const [form, setForm] = useState(initialFormState);

  // Function to open the view modal
  const handleOpenViewModal = (hotelId) => {
    setSelectedHotelId(hotelId);
    setViewModalOpen(true);
  };

  // Function to close the view modal
  const handleCloseViewModal = () => {
    setViewModalOpen(false);
    setSelectedHotelId(null);
  };

  // const handleHotelChange = (event) => {
  //   setForm({ ...form, hotel_id: event.target.value });
  //   setHotelValidationError(false); // Reset the validation error
  //   handleChange(event); // Call handleChange function with the event parameter
  //   handleHotelData(event); // Call handleHotelData function
  // };

  useEffect(() => {
    console.log("formssss", form);
  }, [form]);

  const radioLabelStyle = {
    display: "inline-block",
    marginRight: "10px",
  };

  const radioNameStyle = {
    fontSize: "15px",
    marginLeft: "5px",
  };

  const handleClubsData = () => {
    debugger;
    axios
      .get(`${apiBase}api/auth/getRoteryClubData`)
      .then((response) => {
        debugger;
        setClubName(response.data.data);
        console.log("response.data.data", response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (form.with_room && form.hotel_id && form.room_id) {
      const apiData = {
        with_room: form.with_room,
        hotel_id: form.hotel_id,
        room_id: form.room_id,
      };

      axios
        .post(`${apiBase}api/auth/calculateCharges`, apiData)
        .then((response) => {
          setcalculateCharges(response.data.data);
          const roomCharges = response.data.data.roomCharges;
          const registrationCharges = response.data.data.registrationCharges;
          const totalPayableAmount =
            roomCharges + registrationCharges + (isChildSelected ? 7500 : 0);

          setForm((prevForm) => ({
            ...prevForm,
            room_charges: roomCharges,
            registration_charges: registrationCharges,
            payment_mode : "",
            isAdmin : isAdmin,
            AdminId : AdminId 
          }));
        })

        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [form.with_room, form.hotel_id, form.room_id, isChildSelected]);

  // const handleChange = (e) => {
  //   setHoteslValue(e.target.value);
  //   const { name, value } = e.target;
  //   setForm((prevForm) => ({
  //     ...prevForm,
  //     [name]: value,
  //   }));
  //   console.log("form", form);
  // };

  const handleChange = (e) => {
    debugger;
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      const newValue = checked ? 1 : 0;
      const childRoomChargeValue = checked ? form.no_of_children * 7500 : 0;
      setIsChildSelected((prevIsSelected) => !prevIsSelected);
      setChildRoomCharges(childRoomChargeValue);

      setForm((prevForm) => ({
        ...prevForm,
        [name]: newValue,
        child_room_charges: childRoomChargeValue,
      }));
    } else if (name === "gender") {
      const adult_1_gender = value === "Male" ? "Female" : "Male";
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
        adult_1_gender: adult_1_gender,
      }));
    } else if (name === "no_of_children") {
      const childRoomcharges = parseInt(value) * 7500;
      setChildRoomCharges(childRoomcharges);

      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
        child_room_charges: childRoomcharges,
      }));
    } else if (name === "payment_mode") {
      const calculatedTotalCharges =
        (calculateCharges.registrationCharges || 0) +
        (calculateCharges.roomCharges || 0) +
        childRoomCharges;

      let totalChargesWithPaymentCharge = 0;
      let DisplaytotalChargesWithPaymentCharge = 0;
      debugger;
      if (value === "Credit Card" || value === "Net Banking") {
        totalChargesWithPaymentCharge = calculatedTotalCharges;
        DisplaytotalChargesWithPaymentCharge= calculatedTotalCharges*0.022302;
      } else if (value === "Debit Card") {
        totalChargesWithPaymentCharge = calculatedTotalCharges;
        DisplaytotalChargesWithPaymentCharge= calculatedTotalCharges*0.0118;
      }else if (value === "UPI") {
        totalChargesWithPaymentCharge = calculatedTotalCharges - 5.9;
        DisplaytotalChargesWithPaymentCharge = 5.9;
      }else {
        totalChargesWithPaymentCharge = calculatedTotalCharges;
      }

      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
        total_payable_amount: totalChargesWithPaymentCharge,
      }));
      setDisplaytotalCharge(DisplaytotalChargesWithPaymentCharge)
      setTotalCharge(totalChargesWithPaymentCharge);
      setFormattedTotalCharge(
        totalChargesWithPaymentCharge.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
      );
      setFormattedConvinienceCharge(
        DisplaytotalChargesWithPaymentCharge.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
      );
      let GrandTotal = totalChargesWithPaymentCharge + DisplaytotalChargesWithPaymentCharge;
      setFormattedGrandTotal(
        GrandTotal.toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
        })
      );
    } else {
      // Use functional update to ensure accurate updates based on previous state
      setForm((prevForm) => ({
        ...prevForm,
        [name]: value,
      }));
    }
  };



  const handleHotelData = (e) => {
    // const id = e.target.value;

    axios
      .post(`${apiBase}api/auth/getRoomData`, { id: selectedHotelValue })
      .then((response) => {
        setRoomData(response.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    // Fetch data from the API
    debugger;
    axios
      .get(`${apiBase}api/auth/getHotelData`)
      .then((response) => {
        setHotelData(response.data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });

    // clubData
    handleClubsData();
  }, []);

  let modalImg = IMG;
  let modalDescription =
    "Offering an outdoor pool, spa and wellness centre and 2 restaurants, Treat Resort Silvassa is located in Silvassa. It operates a 24-hour front desk to assist guests at all hours.The accommodation will provide you with air conditioning. There is also a refrigerator. Featuring a shower, private bathroom also comes with free toiletries. Extras include a desk, a safety deposit box and bed linen.";

  if (selectedHotelId === 2) {
    modalImg = raas;
    modalDescription =
      "Offering panoramic views of the Daman Ganga river, this lavish property features stylish rooms, an incredible restaurant, a luxury spa and a wide range of amenities.Rejuvenate with soothing massages and special therapies offered at Santa Maria Spa & Wellness Centre. Unwind with a refreshing dip in the outdoor swimming pool.Spend an enjoyable time playing indoor games such as billiards, table tennis, chess and board games.Tantalise your taste buds with a variety of cuisines at the in-house restaurant, Bistro.";
  }

  const handleSubmit = (e) => {
    debugger;
    e.preventDefault();
   
    axios
      .post(`${apiBase}api/auth/insertHotelData`, form)
      .then((response) => {
        debugger;
        console.log("78979789", response.data);
        if (response.data.success && response.data.data && isAdmin === '1') {
            toast({
              title: "Success",
              description: "Registration Success",
              status: "success",
              duration: 2000,
              position: "top",
              isClosable: true,
            });
            window.location.href = response.data.data.url;
          }
          else if(response.data.success && response.data.data && isAdmin === 0){
            window.location.href = response.data.data;
        } else {
          console.log("Unsuccessful response:", response.data);
        }
        // Navigate("/successfullpayent");
      })
      .catch((error) => {
        console.error(
          "Error fetching data:",
          error.response.data.error
        );
        Navigate("/");
        toast({
          title: "Error",
          description: error.response.data.error,
          status: "error",
          duration: 5000, // Optional: How long the toast should be displayed
          isClosable: true,
          position: "top",
        });
      });
  };

  useEffect(() => {
    handleHotelData();
  }, [selectedHotelValue]);

  let rotryClubList = [];
  for (var i = 0; i < clubName?.length; i++) {
    let rotryClubListObject = {};
    rotryClubListObject.label = clubName[i]?.RotaryClubName;
    rotryClubListObject.value = clubName[i]?.RotaryClubId;
    rotryClubList.push(rotryClubListObject);
  }

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return rotryClubList;
      }
      const updatedItems = rotryClubList.map((list) => {
        console.log("checklisttt", list);
        const newItems = list.RotaryClubName.filter((item) => {
          return item.RotaryClubName.toLowerCase().startsWith(
            searchValue.toLowerCase()
          );
        });
        return { ...list, RotaryClubName: newItems };
      });
      return updatedItems;
    };
  };

  console.log("checkrotrydata", rotryClubList);

  const totalCharges =
    (calculateCharges.registrationCharges || 0) +
    (calculateCharges.roomCharges || 0) +
    (childRoomCharges);

  const formattedTotalCharges = totalCharges.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

  const formattedRegistrationCharges = (
    calculateCharges.registrationCharges || 0
  ).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

  useEffect(() => {
    const totalCharges =
    (calculateCharges.registrationCharges || 0) +
    (calculateCharges.roomCharges || 0) +
    (childRoomCharges);

    setForm((prevForm) => ({
      ...prevForm,
      total_payable_amount: totalCharges,
    }));

    setFormattedTotalCharge(
      totalCharges.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
      })
    );

    paymentModeRef.current.value = "";
  },[totalCharges])


  const renderFormForRoom = (roomId) => {
    switch (roomId) {
      case "1":
      case "2":
      case "4":
      case "6":
      case "7":
        return (
          <SimpleGrid columns={1} spacing={10}>
            <FormControl
              id="adult_1"
              pb={"20px"}
              style={{ width: "100%" }}
              isRequired
            >
              <FormLabel fontSize={"18px"}> Spouse Name</FormLabel>
              <Input
                placeholder="Name"
                _placeholder={{ color: "gray.500" }}
                name="adult_1"
                type="text"
                value={form.adult_1}
                onChange={handleChange}
                bg="rgba(255, 255, 255, 1)"
                borderRadius="6px"
              />
              <FormLabel rowSpan={1} color="red" mt={3}>
              No single person will be alloted in couple room. Only for family 
              </FormLabel>
            </FormControl>
          </SimpleGrid>
        );
      // case "5":
      //   return (
      //     <>
      //       <SimpleGrid columns={2} spacing={10}>
      //         <FormControl
      //           id="adult_1"
      //           pb={"20px"}
      //           style={{ width: "100%" }}
      //           isRequired
      //         >
      //           <FormLabel fontSize={"18px"}>Person 1</FormLabel>
      //           <Input
      //             placeholder="Name"
      //             _placeholder={{ color: "gray.500" }}
      //             name="adult_1"
      //             type="text"
      //             value={form.adult_1}
      //             onChange={handleChange}
      //             bg="rgba(255, 255, 255, 1)"
      //             borderRadius="6px"
      //           />
      //         </FormControl>
      //       </SimpleGrid>
      //       <SimpleGrid columns={2} spacing={10}>
      //         <FormControl
      //           id="adult_2"
      //           pb={"20px"}
      //           style={{ width: "100%" }}
      //           isRequired
      //         >
      //           <FormLabel fontSize={"18px"}>Person 2</FormLabel>
      //           <Input
      //             placeholder="Name"
      //             _placeholder={{ color: "gray.500" }}
      //             name="adult_2"
      //             type="text"
      //             value={form.adult_2}
      //             onChange={handleChange}
      //             bg="rgba(255, 255, 255, 1)"
      //             borderRadius="6px"
      //           />
      //         </FormControl>
      //         <FormControl as="fieldset" pb={"20px"} isRequired>
      //           <FormLabel as="legend" fontSize={"18px"}>
      //             Gender
      //           </FormLabel>
      //           <RadioGroup value={form.adult_2_gender}>
      //             <HStack spacing="24px">
      //               <RedioBox />
      //             </HStack>
      //           </RadioGroup>
      //         </FormControl>
      //       </SimpleGrid>
      //     </>
      //   );

      //   return null; // If no_of_occupant is not 3 for room ID 5
      default:
        return null;
    }
  };

  return (
    <div>
      <img
        src={processed}
        alt="Image Description"
        style={{
          marginTop: "2rem",
          width: "100%",
          height: "auto",
          maxHeight: "250px",
        }}
      />

      <Heading
        paddingTop="10px"
        align={"center"}
        justify={"center"}
        lineHeight={1.1}
        fontSize={{ base: "2xl", md: "3xl" }}
        fontWeight="normal"
        color="rgba(21, 71, 142, 1)"
        style={{
          fontFamily: "Montserrat",
          fontSize: "40px",
          fontStyle: "normal",
        }}
      >
        Registration
      </Heading>
      <Flex minH={"100vh"} align={"center"} justify={"center"}>
        <Stack
          spacing={6}
          w={"full"}
          maxW={"3xl"}
          rounded={"xl"}
          boxShadow={"lg"}
          p={5}
          my={12}
          alignItems={"center"}
          style={{
            background: "rgba(247, 167, 29, 0.10)",
            borderRadius: "10px",
          }}
        >
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <FormControl
              id="member_firstname"
              isRequired
              pb={"20px"}
            >
              <FormLabel fontSize={"18px"}>First Name</FormLabel>
              <Input
                placeholder="First Name"
                _placeholder={{ color: "gray.500" }}
                name="member_firstname"
                type="text"
                value={form.member_firstname}
                onChange={handleChange}
                bg="rgba(255, 255, 255, 1)"
                borderRadius="6px"
                autoFocus
              />
            </FormControl>
            <FormControl
            id="member_surname"
            isRequired
            pb={"20px"}
          >
            <FormLabel fontSize={"18px"}>Last Name</FormLabel>
            <Input
              placeholder="Last Name"
              _placeholder={{ color: "gray.500" }}
              name="member_surname"
              type="text"
              value={form.member_surname}
              onChange={handleChange}
              bg="rgba(255, 255, 255, 1)"
              borderRadius="6px"
              autoFocus
            />
        </FormControl>
            <FormControl
              as="fieldset"
              id="gender"
              isRequired
              pb={"20px"}
            >
              <FormLabel as="legend" fontSize={"18px"}>
                Gender
              </FormLabel>
              <RedioBox />
            </FormControl>

            <FormControl
              id="mobile"
              isRequired
              pb={"20px"}
            >
              <FormLabel fontSize={"18px"}>Mobile Number</FormLabel>
              <Input
                placeholder="Mobile Number"
                _placeholder={{ color: "gray.500" }}
                name="mobile_number"
                type="tel" // Use "tel" type for mobile numbers
                value={form.mobile_number}
                onChange={handleChange}
                bg="rgba(255, 255, 255, 1)"
                borderRadius="6px"
                pattern="[0-9]*" // Allow only digits
                minLength="10" // Minimum length of 10 characters
                maxLength="10" // Maximum length of 10 characters
                title="Invalid Mobile Number" // Error me
              />
            </FormControl>

            <FormControl
              id="email"
              pb={"20px"}
            >
              <FormLabel fontSize={"18px"}>Email</FormLabel>
              <Input
                placeholder="your-email@gmail.com"
                _placeholder={{ color: "gray.500" }}
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                bg="rgba(255, 255, 255, 1)"
                borderRadius="6px"
              // pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}" // Email pattern

              // title="Enter a valid email address" // Error message for invalid email format
              />
            </FormControl>
            <FormControl isRequired pb={"20px"}>
              <FormLabel>Rotary Club Name / Other District Guest</FormLabel>
              <ReactSelect
                options={rotryClubList}
                isSearchable="true"
                onChange={(e) => {
                  // setSelectedClub(e.value);
                  setForm((prevForm) => ({
                    ...prevForm,
                    rotary_club_id: e.value,
                  }));
                  setIsValid(true);
                  if (e.value === "") {
                    setIsValid(false);
                  }
                }}
                // onChange={handleChange}
                placeholder="Select Rotary Club"
                filterOptions={handleFilter}
                required
              />
            </FormControl>
            <FormControl
              as="fieldset"
              id="hotel_id"
              isRequired
              pb={"20px"}
              style={{ width: "50%" }}
            >
              <FormLabel as="legend" fontSize={"18px"}>
                Choose Hotel
              </FormLabel>
              <RadioGroup value={form.hotel_id} isRequired>
                <RedioBox1 />
              </RadioGroup>
              {form.hotel_id === "2" &&   
              <FormLabel rowSpan={1} color="red" mt={3}>
                7 km away from venue
              </FormLabel>}
            

              <Modal isOpen={isViewModalOpen} onClose={handleCloseViewModal}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>View Hotel Details</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Box textAlign="center">
                      <img
                        src={modalImg}
                        alt="Hotel"
                        style={{
                          maxWidth: "100%",
                          marginBottom: "1rem",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                    <Text fontWeight="bold" mb="1.5rem">
                      {modalDescription}
                    </Text>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      colorScheme="blue"
                      mr={3}
                      onClick={handleCloseViewModal}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </FormControl>
            <FormControl pb={"20px"} isRequired>
              <FormLabel>Select Room Type</FormLabel>
              <ChakraSelect
                name="room_id"
                isRequired
                onChange={(e) => {
                  setForm((prevForm) => ({
                    ...prevForm,
                    room_id: e.target.value,
                    // payment_mode : "",
                  }));
                }}
                // onChange={handleChange}
                bg="rgba(255, 255, 255, 1)"
                borderRadius="6px"
                placeholder="Select Room Type"
              >
                {roomData
                  .filter((room) => {
                    // Convert isAdmin to a number before comparison
                    const isAdminNumber = parseInt(isAdmin, 10);
                    const showRoom =
                    (room.CreatedFor === 0 || (room.CreatedFor === 1 && isAdminNumber === 1)) 
                    // const showRoom =(room.CreatedFor === 1 && isAdminNumber === 1)
                    // ?(room.CreatedFor === 1 && isAdminNumber === 1) 
                    // :(room.CreatedFor === 0 && isAdminNumber === 0)   
                    return showRoom;
                  })
                  .map(function (room) {
                    return (
                      <option key={room.RoomId} value={room.RoomId} disabled={room.AvailableRooms === 0 || room.AvailableRooms === undefined}>
                        {room.RoomName}
{/*                        {room.AvailableRooms !== null ? ` (Available Rooms- ${room.AvailableRooms})` : ''}*/}
                      </option>
                    );
                  })}
              </ChakraSelect>
            </FormControl>
        

            {renderFormForRoom(form.room_id)}

            {form.no_of_occupant === "3" && (
              <>
                <SimpleGrid columns={2} spacing={10}>
                  <FormControl
                    id="adult_1"
                    pb={"20px"}
                    style={{ width: "100%" }}
                    isRequired
                  >
                    <FormLabel fontSize={"18px"}>Person 1</FormLabel>
                    <Input
                      placeholder="Name"
                      _placeholder={{ color: "gray.500" }}
                      name="adult_1"
                      type="text"
                      value={form.adult_1}
                      onChange={handleChange}
                      bg="rgba(255, 255, 255, 1)"
                      borderRadius="6px"
                    />
                  </FormControl>
                  <FormControl as="fieldset" pb={"20px"} isRequired>
                    <FormLabel as="legend" fontSize={"18px"}>
                      Gender
                    </FormLabel>
                    <RadioGroup value={form.adult_1_gender}>
                      <HStack spacing="24px">
                        <Radio
                          name="adult_1_gender"
                          value="Male"
                          onChange={handleChange}
                        >
                          Male
                        </Radio>
                        <Radio
                          name="adult_1_gender"
                          value="Female"
                          onChange={handleChange}
                        >
                          Female
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={2} spacing={10}>
                  <FormControl
                    id="adult_2"
                    pb={"20px"}
                    style={{ width: "100%" }}
                    isRequired
                  >
                    <FormLabel fontSize={"18px"}>Person 2</FormLabel>
                    <Input
                      placeholder="Name"
                      _placeholder={{ color: "gray.500" }}
                      name="adult_2"
                      type="text"
                      value={form.adult_2}
                      onChange={handleChange}
                      bg="rgba(255, 255, 255, 1)"
                      borderRadius="6px"
                    />
                  </FormControl>
                  <FormControl as="fieldset" pb={"20px"} isRequired>
                    <FormLabel as="legend" fontSize={"18px"}>
                      Gender
                    </FormLabel>
                    <RadioGroup value={form.adult_2_gender}>
                      <HStack spacing="24px">
                        <Radio
                          name="adult_2_gender"
                          value="Male"
                          onChange={handleChange}
                        >
                          Male
                        </Radio>
                        <Radio
                          name="adult_2_gender"
                          value="Female"
                          onChange={handleChange}
                        >
                          Female
                        </Radio>
                      </HStack>
                    </RadioGroup>
                  </FormControl>
                </SimpleGrid>
              </>
            )}
            {form.room_id !== '3' && form.room_id !== '5' && (
              <HStack
                spacing="24px"
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  marginBottom: "20px",
                  marfinTop: "20px",
                }}
              >
                <input
                  type="checkbox"
                  name="isChild_1"
                  id="isChild_1"
                  checked={form.isChild_1}
                  onChange={handleChange}
                />
                <label htmlFor="isChild_1">
                  Do you want to add a child ? (Age group 5-11)
                </label>
              </HStack>)}
            {form.isChild_1 && (
              <FormControl id="no_of_children" isRequired>
                <FormLabel>Number of Children (Max 2)</FormLabel>
                <ChakraSelect
                  name="no_of_children"
                  value={form.no_of_children}
                  onChange={handleChange}
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                </ChakraSelect>
              </FormControl>
            )}
            <FormControl as="fieldset" pb={"20px"} isRequired>
              <FormLabel as="legend" fontSize={"18px"}>
                Choose Your Food Preference
              </FormLabel>

              <RadioGroup>
                <RedioBox2 />
              </RadioGroup>
            </FormControl>
            <FormControl as="fieldset" pb={"20px"} isRequired>
              <FormLabel as="legend" fontSize={"18px"}>
              Do you want to Cocktail? (Payable)
              </FormLabel>
            <RadioGroup>
              <RedioBoxForMocktail />
            </RadioGroup>
          </FormControl>
            <FormControl as="fieldset" pb={"20px"} isRequired>
              <FormLabel as="legend" fontSize={"18px"}>
                How will you be coming to the conference?
              </FormLabel>
              <RadioGroup value={form.travel_mode}>
                <RedioBox3 />
              </RadioGroup>
            </FormControl>
            <FormControl pb={"20px"} isRequired>
              <FormLabel>Payment Mode</FormLabel>
              <ChakraSelect
                name="payment_mode"
                isRequired
                ref={paymentModeRef} 
                onChange={handleChange}
                bg="rgba(255, 255, 255, 1)"
                borderRadius="6px"
                placeholder="Select Payment Mode"
                isDisabled={form.hotel_id === "" || form === ""}
              >
              {isAdmin === "1" ? (
                <>
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Online">Online</option>
                </>
              ) : (
                  <>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Debit Card">Debit Card</option>
                    <option value="Net Banking">Net Banking</option>
                    <option value="UPI">UPI</option>
                  </>
                )}

              </ChakraSelect>
              {(form.payment_mode !== 'UPI' && form.payment_mode !== '' && isAdmin !== "1") ?
              <FormLabel rowSpan={1} color="red" mt={3}>
              UPI is Preferrable Payment Mode.
              </FormLabel>:null}
            </FormControl>
            {isAdmin === "1" ? (
              <FormControl pb={"20px"}>
                <FormLabel>Notes</FormLabel>
                <Textarea
                  name="payment_note"
                  value={form.payment_note}
                  onChange={handleChange}
                  bg="rgba(255, 255, 255, 1)"
                  borderRadius="6px"
                  placeholder="Add your notes here..."
                />
              </FormControl>
            ) : (
              null
            )}

            <Stack spacing={6} alignItems={"center"}>
              {totalCharges > 0 && (
                <div>
                  <Text
                    mt="2"
                    fontSize="md"
                    borderRadius="md"
                    textAlign="center"
                    marginX="auto"
                  >
                    {FormData.payment_mode !== '' ? (<span>Total Charges - {formattedTotalCharge !== null ? `${formattedTotalCharge}` : formattedTotalCharges}</span>) : null}
                  </Text>
                  <Text
                    mt="2"
                    fontSize="md"
                    borderRadius="md"
                    textAlign="center"
                    marginX="auto"
                  >
                    {form.payment_mode !== '' ? (<span>Convenience Charges - {formattedConvinienceCharge !== null ? `${formattedConvinienceCharge}` : formattedConvinienceCharge}</span>) : null}
                  </Text>
                  <Text
                    mt="2"
                    fontSize="md"
                    borderRadius="md"
                    fontWeight="bold"
                    fontSize="xl"
                    textAlign="center"
                    marginX="auto"
                  >
                    {form.payment_mode !== '' ? (<span>Grand Total - {formattedGrandTotal !== null ? `${formattedGrandTotal}` : formattedGrandTotal}</span>) : null}
                  </Text>
                  <Text
                    mt="2"
                    fontSize="md"
                    borderRadius="md"
                    textAlign="center"
                    marginX="auto"
                  >
                    (Including Registration Charges of {formattedRegistrationCharges}
                    {form.isChild_1 && (
                      <>
                        {" "}
                        and Child Registration Charges of {" "}
                        {new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR'
                        }).format(childRoomCharges)}
                      </>
                    )}
                    )
                  </Text>
                </div>
              )}

              {isLoading ? (
                <Button
                  isLoading
                  loadingText="Loading"
                  width={"200px"}
                  bg={"rgba(21, 71, 142, 1)"}
                  color={"white"}
                  _hover={{
                    bg: "rgba(21, 71, 142, 1)",
                  }}
                  type="submit"
                >
                  Pay
                </Button>
              ) : (
                <Button
                  width={"200px"}
                  bg={"rgba(21, 71, 142, 1)"}
                  color={"white"}
                  _hover={{
                    bg: "rgba(21, 71, 142, 1)",
                  }}
                  type="submit"
                  isDisabled
                >
                  Proceed to Pay
                </Button>
              )}
              <Text
                mt="2"
                fontSize="15px"
                borderRadius="md"
                textAlign="center"
                marginX="auto"
              >
                By clicking above button, I agree to the <br />
                <a
                  href="https://my.rotary.org/en/rotary-international-website-terms-use"
                  target="_blank"
                  style={{ color: "red" }}
                >
                  Terms and Conditions
                </a>{" "}
                and
                <a
                  href="https://my.rotary.org/en/privacy-policy"
                  target="_blank"
                  style={{ color: "red" }}
                >
                  {" "}
                  Privacy Policy.
                </a>
              </Text>
            </Stack>
          </form>
        </Stack>
      </Flex>
      <img
        src={ContactFooter}
        alt="Image Description"
        style={{
          marginTop: "2rem",
          width: "100%",
          height: "auto",
          maxHeight: "250px",
        }}
      />
      {isError && (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Wrong Credentials</AlertTitle>
          <AlertDescription>
            Please Add Correct Email And Password
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default Signup;
