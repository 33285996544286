
import Navbar from '../componets/Navbar'
import Footer from '../componets/Footer'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  Box,
  useToast,
  FormHelperText,
  Text,
  Grid,
  Image,
  GridItem

} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { signupUser } from '../redux/userSignup/userSignup.action';
import { ISACTIVE } from '../redux/userSignup/userSignup.type';
import GroupLogo from '../assets/Group 26586.png';
import { SimpleGrid } from '@chakra-ui/react'
import Group36907 from "../assets/Group 36907.png"

const FailedPayment = () => {
  const personid = window.location.pathname.split('/').slice(-1)[0];
  const { isAuth, isError, isErrorMsg, isActive, isLoading } = useSelector(store => store.userSignup);
  const dispatch = useDispatch();
  const toast = useToast();
  const apiBase = process.env.REACT_APP_MAIN_URL;
  const [successData, setSuccessData] = useState([])
  
  useEffect(() => {
    debugger;
    const apiData = {
        ResponseId: personid
    };
    axios
        .post(`${apiBase}api/payment/getPaymentReceiptData`, apiData)
        .then((response) => {
            setSuccessData(response.data.data[0]);
        })
        .catch((error) => {
            // Handle the error if needed
        });
}, []);

  return (
    <>
      <Navbar />
      <Flex color="white" pt={10}>
        <Box flex="2" flexDirection={"row"} as="b">
          <Text
            textAlign="center"
            lineHeight={1.1}
            color="rgba(21, 71, 142, 1)"
            style={{ fontFamily: "Montserrat", fontSize: "40px" }}
          >
            District Conference
          </Text>
          <Text
            textAlign="center"
            lineHeight={1.1}
            color="rgba(21, 71, 142, 1)"
            style={{ fontFamily: "Montserrat", fontSize: "40px" }}
          >
            Registration
          </Text>
        </Box>
      </Flex>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="40vh"
        flexDirection="column"
      >
        <Image src={Group36907} w="90px" />
        <Text
          lineHeight={1.1}
          color="rgba(21, 71, 142, 1)"
          style={{
            fontFamily: "Montserrat",
            fontSize: "26px",
            fontWeight: "600",
          }}
          pt="10px"
        >
          Payment Pending
        </Text>

        <Text
          lineHeight={1.1}
          color="rgba(21, 71, 142, 1)"
          style={{
            fontFamily: "Montserrat",
            fontSize: "26px",
            fontWeight: "600",
          }}
          pt="10px"
        >
          <span style={{ color: "black", fontWeight: "600" }}>Total</span> -
          ₹{successData.paid_amount}/-
        </Text>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="10vh"
        width="100%"
      >
        <Box
          width="50%"
          flexDirection="column"
          border="4px solid black"
          padding="5px"
        >
           <Box>
            <Flex
              alignItems="center"
              justifyContent="center"
              //   minHeight="10vh"
              width="100%"
              fontSize="20px"
              as="b"
            >
              <Text>Booking Details</Text>
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="space-around"
              //   minHeight="10vh"
              width="100%"
              fontSize="15px"
            >
              <Grid container spacing={12}>
                <Grid item xs={3}>
                  <Text p="10px">Name: {successData.customer_name}</Text>
                </Grid>
                <Grid container spacing={3}>
                  <Text p="10px">Mobile Number: {successData.mobile}</Text>
                </Grid>
                <Grid container spacing={3}>
                  <Text p="10px">Club Name: {successData.club_name}</Text>
                </Grid>
              </Grid>
            </Flex>

            <Flex
              alignItems="center"
              justifyContent="space-evenly"
              //   minHeight="10vh"
              width="100%"
              fontSize="15px"
            >
              <Grid container spacing={12}>
                <Grid container spacing={3}>
                  <Text p="10px">
                    Venue: <span style={{ fontWeight: 700 }}>{successData.hotel_name}</span>
                  </Text> </Grid>
                <Grid container spacing={3}>
                  <Text p="10px">
                    Room Type:{" "}
                    <span style={{ fontWeight: 700 }}>{successData.room_name}</span>
                  </Text>
                </Grid>
              </Grid>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-evenly"
              //   minHeight="10vh"
              width="100%"
              fontSize="15px"
            >
              <Grid container spacing={12}>
                <Text p="10px">
                  Date: <span style={{ fontWeight: 700 }}>{successData.payment_datetime}</span>
                </Text>
              </Grid>
            </Flex>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="10vh"
        width="100%"
      >
        <a href="/" style={{ textDecoration: "none" }}>
          <Button
            width={"200px"}
            bg={"rgba(21, 71, 142, 1)"}
            color={"white"}
            _hover={{
              bg: "rgba(21, 71, 142, 1)",
            }}
            type="submit"
            borderRadius="30px"
          >
            Retry
          </Button>
        </a>
        {isError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Wrong Credentials</AlertTitle>
            <AlertDescription>
              Please Add Correct Email And Password
            </AlertDescription>
          </Alert>
        )}
      </Box>
      <Footer />
    </>
  )
}

export default FailedPayment