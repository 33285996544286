import React, { useEffect, useState } from "react";

import {Container,Heading} from "@chakra-ui/react";
import axios from 'axios';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'

const AdminShowUser = () => {
  const [hotelsList, setHotelsList] = useState([]);
  const apiBase = process.env.REACT_APP_MAIN_URL;

  useEffect(() => {
    axios
      .get(`${apiBase}api/auth/gethotelroomsdata`)
      .then((response) => {
        setHotelsList(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div>
      <Container maxW={"100%"} mt={"20px"}>
        <Heading textAlign={"center"}>Room Detail</Heading>
          <div>
          <TableContainer>
            <Table size='lg' variant="striped">
              <Thead>
                <Tr>
                  <Th>Hotel Name</Th>
                  <Th>Room Name</Th>
                  <Th>Available Room</Th>
                  <Th>Registered Person Count</Th>
                </Tr>
              </Thead>
              <Tbody>
                {hotelsList.map((result) => (
                  <Tr >
                    <Td>{result.hotel_name}</Td>
                    <Td>{result.room_name}</Td>
                    <Td>{result.available_room}</Td>
                    <Td>{result.registered_person_count}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
      </Container>

    </div>
  );
};

export default AdminShowUser;