import React, { useState } from 'react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Image,
    Input,
    Stack,
    Box,
    Text,
    Link
} from "@chakra-ui/react";
import { useToast } from '@chakra-ui/react'
import { Navigate, useNavigate } from "react-router-dom";
import Navbar from '../componets/Navbar';
import Footer from '../componets/Footer';
import Group36903 from '../assets/Group 36903.png'
import axios from 'axios';
import { useHistory } from "react-router-dom";

const Login = () => {

    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [showOtp, setShowOtp] = useState(false);
    const [isError, setIsError] = useState(false);
    const [resendAttempts, setResendAttempts] = useState(0);

    const toast = useToast();
    let navigate = useNavigate();

    const apiBase = process.env.REACT_APP_MAIN_URL;

    const handleResendOtp = () => {
        if (resendAttempts < 3) { // Limit the number of resend attempts
            setResendAttempts(resendAttempts + 1);
            axios
                .post(`${apiBase}api/auth/verifyphone`, { phoneNumber })
                .then(() => {
                    toast({
                        title: "OTP resent successfully",
                        status: "success",
                        isClosable: true,
                        duration: 2000,
                        position: "top",
                    });
                })
                .catch((error) => {
                    console.error("Error resending OTP:", error);
                    toast({
                        title: "Failed to resend OTP",
                        description: "An error occurred while resending OTP. Please try again.",
                        status: "error",
                        isClosable: true,
                        duration: 4000,
                        position: "top",
                    });
                });
        } else {
            toast({
                title: "Exceeded maximum resend attempts",
                description: "You have exceeded the maximum number of OTP resend attempts.",
                status: "warning",
                isClosable: true,
                duration: 4000,
                position: "top",
            });
        }
    };


    const handlePhoneSubmit = (e) => {
        e.preventDefault();
        setIsError(false); // Reset error status

        // Perform phone number validation here
        // If validation succeeds, make an API call
        // For example:
        if (phoneNumberIsValid(phoneNumber)) {
            // Log the phone number before making the API call
            axios
                .post(`${apiBase}api/auth/verifyphone`, { phoneNumber })
                .then((response) => {
                    toast({
                        title: "OTP sent successfully",
                        status: "success",
                        isClosable: true,
                        duration: 2000,
                        position: "top",
                    });
                    setShowOtp(true);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    toast({
                        title: "Mobile Number Not found",
                        status: "error",
                        isClosable: true,
                        duration: 4000,
                        position: "top",
                    });
                    setShowOtp(false); // Show phone number input again
                });
            console.log('Phone Number:', phoneNumber);
        } else {
            setIsError(true);
        }
    };

    const handleOtpSubmit = (e) => {
        e.preventDefault();

        if (otpIsValid(otp)) {
            // Navigate or perform other actions here
            axios
                .post(`${apiBase}api/auth/verifyotp`, { phoneNumber, otp })
                .then((response) => {
                    const { response_id } = response.data.data;
                    // Navigate to PaymentReceipt component with response_id as a URL parameter
                    navigate(`/paymentreceipt/${response_id}`);
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                    toast({
                        title: "Failed to verify OTP",
                        description: "An error occurred while verifying OTP. Please try again.",
                        status: "error",
                        isClosable: true,
                        duration: 4000,
                        position: "top",
                    });
                });
            console.log('Phone Number:', phoneNumber);
        } else {
            setIsError(true);
        }
    };



    const phoneNumberIsValid = (phoneNumber) => {
        // Implement your phone number validation logic here
        // Return true if the phone number is valid, otherwise false
        return true;
    };

    const otpIsValid = (otp) => {


        return true;
    };

    return (
        <div>
            <Navbar />
            <Flex
                minH={"100vh"}
                align={"center"}
                justify={"center"}
            >
                <Stack
                    spacing={6}
                    w={"full"}
                    maxW={"md"}
                    rounded={"xl"}
                    boxShadow={"lg"}
                    p={5}
                    my={12}
                    alignItems={"center"}
                >
                    <Image
                        src={Group36903}
                        width={"60%"}
                    />
                    <Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
                        Get Registration Receipt
                    </Heading>
                    {showOtp ? (
                        <form onSubmit={handleOtpSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <FormControl id="otp" isRequired pb={"20px"}>
                                <FormLabel fontSize={"18px"}>OTP</FormLabel>
                                <Input
                                    placeholder="Enter OTP"
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                            </FormControl>
                            <Button
                                loadingText='Loading'
                                width={"200px"}
                                bg={"green.700"}
                                color={"white"}
                                variant='outline'
                                spinnerPlacement='start'
                                _hover={{
                                    bg: "green.600",
                                }}
                                type="submit"
                            >
                                Verify OTP
                            </Button>

                            <Box mt={4}>
                                <Text fontSize="sm" color="gray.600">
                                    {resendAttempts < 3 ? (
                                        `Didn't receive the OTP? `
                                    ) : (
                                        "Maximum resend attempts reached"
                                    )}
                                    {resendAttempts < 3 && (
                                        <Link color="blue.500" onClick={handleResendOtp}>
                                            Resend OTP
                                        </Link>
                                    )}
                                </Text>
                            </Box>

                        </form>

                    ) : (
                        <form onSubmit={handlePhoneSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>

                            {/* Phone Number Input */}
                            <FormControl id="phoneNumber" isRequired pb={"20px"}>
                                <FormLabel fontSize={"18px"}>Phone Number</FormLabel>
                                <Input
                                    placeholder="Enter your phone number"

                                    value={phoneNumber}
                                    maxLength={10}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </FormControl>
                            <Button

                                loadingText='Loading'
                                width={"200px"}
                                bg={"green.700"}
                                color={"white"}
                                variant='outline'
                                spinnerPlacement='start'
                                _hover={{
                                    bg: "green.600",
                                }}
                                type="submit"
                            >
                                Send OTP
                            </Button>


                        </form>
                    )}
                </Stack>
            </Flex>
            {isError && (
                <Alert status='error'>
                    <AlertIcon />
                    <AlertTitle>Wrong Credentials</AlertTitle>
                    <AlertDescription>Please Add Correct Phone Number or OTP</AlertDescription>
                </Alert>
            )}
            <Footer />
        </div>
    );
}

export default Login;
