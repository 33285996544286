import { React, useState } from "react";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  PopoverTrigger,
  Popover,
  Portal,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  PopoverArrow,
  PopoverFooter,
  Box,
  Spacer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../redux/userLogin/userLogin.action";
import Group36903 from "../assets/Logo with theme_230905_163337 (1)_page-0001.jpg";
import Flyer from "../assets/Conference Flyer.jpg";
import PopupForOtp from "./PopupForOtp";
import Adwait from "../assets/adwait-logo.png";

const Navbar = () => {
  const location = useLocation();
  const currentPage = location.pathname;
  const { isAuth } = useSelector((store) => store.userLogin);
  const dispatch = useDispatch();
  const [isViewModalOpen, setViewModalOpen] = useState(false);

  const handleOpenViewModal = () => {
    setViewModalOpen(true);
  };

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
  };

  return (
    <div className="main-wrpa">
      <div className="header">
        <div className="adwait-logo">
        <Link to="/">
          <Image src={Adwait} alt="Logo" />
          </Link>
        </div>
        <div className="left-part">
          <Link to="/">
            <Image src={Group36903} alt="Logo" />
          </Link>
        </div>
        <div className="right-part">
          {currentPage !== "/getregistrationteceipt" ? (
            <Link to="/getregistrationteceipt">
              <Button
                m="1px 1px"
                bg="rgba(21, 71, 142, 1)"
                colorScheme="white"
                borderRadius="30px"
              >
                Get Registration Receipt
              </Button>
            </Link>
          ) : null}
          {!isAuth ? (
            <>
              <Link to="/admin-login">
                {currentPage !== "/admin-login" ? (
                  <Button
                    m="1px 1px"
                    bg="rgba(21, 71, 142, 1)"
                    colorScheme="white"
                    borderRadius="30px"
                  >
                    Admin login
                  </Button>
                ) : null}
              </Link>
            </>
          ) : (
            <>
              <Button m="0px 10px">Welcome</Button>
              <Button
                onClick={() => {
                  dispatch(userLogout());
                }}
                m="0px 10px"
                bg="#3167FF"
                colorScheme="white"
              >
                Logout
              </Button>
            </>
          )}
        </div>
      </div>
      {/* <Box p="4">
          <GridItem
            paddingLeft="20px"
            w="250px"
            h="15"
            justifySelf={{ base: "center", md: "left" }}
          >
            <Link to="/">
              <Image src={Group36903} alt="Logo" />
            </Link>
          </GridItem>
        </Box> */}

      <Modal isOpen={isViewModalOpen} size="md" onClose={handleCloseViewModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Umang - 2024</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box textAlign="center">
              <img
                src={Flyer}
                alt="Hotel"
                style={{
                  maxWidth: "100%",
                  marginBottom: "1rem",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseViewModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/*
        <Box p="4">
          <Flex>
            {!isAuth ? (
              <>
                <Link to="/admin-login">
                  {currentPage !== "/admin-login" ? (
                    <Button
                      m="1px 1px"
                      bg="rgba(21, 71, 142, 1)"
                      colorScheme="white"
                      borderRadius="30px"
                    >
                      Admin login
                    </Button>
                  ) : null}
                </Link>
              </>
            ) : (
              <>
                <Button m="0px 10px">Welcome</Button>
                <Button
                  onClick={() => {
                    dispatch(userLogout());
                  }}
                  m="0px 10px"
                  bg="#3167FF"
                  colorScheme="white"
                >
                  Logout
                </Button>
              </>
            )}
          </Flex>
        </Box> */}
    </div>
  );
};

export default Navbar;
