import "./App.css";
import AllRoutes from "./pages/AllRoutes";

import {useState, useEffect} from "react"

function App() {
  
  const [loading,setLoading] = useState(false)


  return (
    <div className="App">
      {/* only use app.js file when you working ... remove all code before push or commit  */}
      {
        <AllRoutes />
      }

     
      

    </div>
  );
}

export default App;
