import React, { useState, useEffect } from 'react';
import { Button,   useToast, } from '@chakra-ui/react';
import rotarylogo from "../assets/rotary-logo.png";
import axios from 'axios';



const PaymentReceipt = () => {
  const [successData, setSuccessData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const toast = useToast();

  const personid = window.location.pathname.split('/').slice(-1)[0];
    const apiBase = process.env.REACT_APP_MAIN_URL;
  

    useEffect(() => {
        debugger;
        const fetchPaymentData = async () => {
          try {
            const apiData = {
              ResponseId: personid,
            };
            const response = await axios.post(`${apiBase}api/payment/getPaymentReceiptData`, apiData);
      
            if (response.data.data[0].error_message) {
              // Handle the case when payment is not successful
              setErrorMessage(response.data.data[0].error_message);
              toast({
                title: 'Payment Not Successful',
                description: 'Receipt cannot be generated. Please make the payment first.',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            } else {
              // Payment was successful, update the data
              setSuccessData(response.data.data[0]);
            }
          } catch (error) {
            // Handle the error if needed
          }
        };
        fetchPaymentData();
      }, [personid, apiBase, toast]);
      
    
    useEffect(() => {
        if (successData.response_number) {
          handlePrint();
        }
      }, [successData]);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');

   
    const printContent  = () => `
    <!DOCTYPE html>
    <html>
    <head>
    <title>Payment Receipt</title>
    <style>
        body {
            font-family: Arial, sans-serif;
            background-color: #f2f2f2;
            margin: 0;
            padding: 0;
        }
        .container {
            border: 2px solid #ccc;
            padding: 30px;
            background-color: white;
            width: 100%;
            max-width: 680px;
            margin: 40px auto;
            border-radius: 8px;
            box-shadow: 0px 0px 10px #ccc;
        }
        .header {
            text-align: center;
            margin-bottom: 20px;
        }
        .logo {
            max-height: 60px;
            margin-bottom: 10px;
        }
        .info {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        .right-top-corner {
            text-align: right;
        }
        .content {
            margin-top: 20px;
        }
        .highlighted {
            background-color: #ffe600;
            padding: 15px;
            margin-top: 20px;
            border-radius: 5px;
            font-weight: bold;
        }
        .button-container {
            text-align: center;
            margin-top: 30px;
        }
        button {
            padding: 15px 30px;
            font-size: 16px;
            cursor: pointer;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            transition: background-color 0.3s, color 0.3s;
        }
        button:hover {
            background-color: #0056b3;
            color: white;
        }
        /* Responsive styling */
        @media (max-width: 500px) {
            .info {
                flex-direction: column;
            }
            .right-top-corner {
                text-align: left;
            }
     
        }
        @media print {
            .highlighted {
                -webkit-print-color-adjust: exact;
                print-color-adjust: exact;
                background-color: #ffe600 !important;
            }
            .print-hide {
                display: none;
            }
        }
        .h111{
            color: green;
            text-decoration: underline;
        }
    </style>
</head>

    <body>
    <div class="container">
    <div class="header">
        <img src=${rotarylogo} alt="Logo" class="logo">
        <h1 class="h111">Payment Receipt</h1> 
        <h2>Rotary District Conference Umang, Silvassa (Jan 5-7, 2024)</h2>
    </div>
    <div class="info">
        <div class="left">
            <p><strong>Booking No:</strong>${successData.response_number}</p>
        </div>
        <div class="right-top-corner">
            <p><strong>Booking Date:</strong> ${successData.payment_datetime}</p>
        </div>
    </div>
    <div class="content">
        <p><strong>Received From:</strong> <span>${successData.customer_name}</span>
        <p><strong>Rotary Club Name:</strong> <span>${successData.club_name}</span></p>
        <p><strong>Mobile:</strong> <span>${successData.mobile}</span></p>
        <p><strong>No of Persons:</strong> <span>${successData.personCount}</span></p>
        <p><strong>Amount Received:</strong> <span>${successData.paid_amount}</span></p>

      

        <p><strong>Hotel name:</strong> <span>${successData.hotel_name}</span></p>
        <p><strong>Room Type:</strong> <span>${successData.room_name}</span></p>
        
        <p><strong>Payment Mode:</strong> <span>${successData.payment_mode}</span></p>
    
    </div>
    <section class="highlighted">
    - January 5th & 6th Night stay<br/>
    - January 7th: Morning breakfast and checkout.<br/>
    <br/>
    Please bring any Govt approved ID like Aadhaar Card or Driver's License for all the persons accompanying you along with this receipt when you arrive at the conference.
</section>
<div class="button-container">
    <a href="/getregistrationteceipt"> <button class="print-hide">Done</button></a>
    <button class="print-hide" onclick="window.print()">Download Receipt</button>
</div>

</div>
    </body>
    </html>
    `;

    const contentToPrint = printContent(successData);

    printWindow.document.write(contentToPrint);
    printWindow.document.close();
    // printWindow.print();
    

  };


  return (
 <></>
  );
};

export default PaymentReceipt;

