import Navbar from '../componets/Navbar'
import Footer from '../componets/Footer'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Flex,
    Box,
    HStack,
    Radio,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Select,
    useToast,
    FormHelperText,
    Text,
    Grid,
    Image,
    GridItem

} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { signupUser } from '../redux/userSignup/userSignup.action';
import { ISACTIVE } from '../redux/userSignup/userSignup.type';
import GroupLogo from '../assets/Group 26586.png';
import { SimpleGrid } from '@chakra-ui/react'
import Group36873 from "../assets/Group 36873.png"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


const Successfullpay = () => {
    const personid = window.location.pathname.split('/').slice(-1)[0];
    const { isAuth, isError, isErrorMsg, isActive, isLoading } = useSelector(store => store.userSignup);
    const dispatch = useDispatch();
    const toast = useToast();
    const apiBase = process.env.REACT_APP_MAIN_URL;
    const [successData, setSuccessData] = useState([])

    console.log("checkiiid", personid)
    useEffect(() => {
        debugger;
        const apiData = {
            ResponseId: personid
        };
        axios
            .post(`${apiBase}api/payment/getPaymentReceiptData`, apiData)
            .then((response) => {
                if (response.data.data[0].payment_status === 'SUCCESS') {
                    setSuccessData(response.data.data[0]);
                } else {
                    if (response.data.data[0].payment_status === 'WAITING-BANK-RESPONSE' || response.data.data[0].payment_status === "TRANSACTION-INCOMPLETE") {
                        window.location.href = `/payment/Pending/${personid}`;
                      } else if (response.data.data[0].payment_status === 'FAILED') {
                        // Navigate to "Failed" page
                        window.location.href = `/payment/Failed/${personid}`;
                      }
                }
            })
            .catch((error) => {
                // Handle the error if needed
            });
    }, []);
    

    const generateReceipt = () => {
        const pdf = new jsPDF();

        const receiptElement = document.getElementById('receipt');

        html2canvas(receiptElement).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            pdf.addImage(imgData, 'PNG', 10, 10, 190, 0);
            pdf.save('payment_receipt.pdf');
        });
    };

    return (
        <>
            <Navbar />
            <Stack
                spacing={69}
                id="receipt"
                p={5}
                my={12}
                alignItems={"center"}
            // style={{
            //     background: "rgba(247, 167, 29, 0.10)",
            //     borderRadius: "10px",
            // }}
            >
                <Flex color="white" pt={10}>
                    <Box flex="2" flexDirection={"row"} as="b">
                        <Text
                            textAlign="center"
                            lineHeight={1.1}
                            color="rgba(21, 71, 142, 1)"
                            style={{ fontFamily: "Montserrat", fontSize: "40px" }}
                        >
                            District Conference
                        </Text>
                        <Text
                            textAlign="center"
                            lineHeight={1.1}
                            color="rgba(21, 71, 142, 1)"
                            style={{ fontFamily: "Montserrat", fontSize: "40px" }}
                        >
                            Registration
                        </Text>
                    </Box>
                </Flex>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="40vh"
                    flexDirection="column"
                >
                    <Image src={Group36873} w="90px" />
                    <Text
                        lineHeight={1.1}
                        color="rgba(21, 71, 142, 1)"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: "26px",
                            fontWeight: "600",
                        }}
                        pt="10px"
                    >
                        Payment Successful
                    </Text>

                    <Text
                        lineHeight={1.1}
                        color="rgba(21, 71, 142, 1)"
                        style={{
                            fontFamily: "Montserrat",
                            fontSize: "26px",
                            fontWeight: "600",
                        }}
                        pt="10px"
                    >
                        <span style={{ color: "black", fontWeight: "600" }}>Total</span> -
                        ₹{successData.paid_amount}/-
                    </Text>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="10vh"
                    width="100%"
                >
                    <Box
                        width="50%"
                        flexDirection="column"
                        border="4px solid black"
                        padding="5px"
                    >
                        <Box>
                            <Flex
                                alignItems="center"
                                justifyContent="center"
                                //   minHeight="10vh"
                                width="100%"
                                fontSize="20px"
                                as="b"
                            >
                                <Text>Booking Details</Text>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-around"
                                //   minHeight="10vh"
                                width="100%"
                                fontSize="15px"
                            > <Grid container spacing={12}>
                                  <Grid item xs={3}>
                                        <Text p="10px">Name: {successData.customer_name}</Text>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Text p="10px">Mobile Number: {successData.mobile}</Text>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Text p="10px">Club Name: {successData.club_name}</Text>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Text p="10px">
                                            Venue: <span style={{ fontWeight: 700 }}>{successData.hotel_name}</span>
                                        </Text>
                                    </Grid>
                                    <Grid container spacing={3}>
                                        <Text p="10px">
                                            Room Type:{" "}
                                            <span style={{ fontWeight: 700 }}>{successData.room_name}</span>
                                        </Text>
                                    </Grid>
                                </Grid>
                            </Flex>
                            <Flex
                                alignItems="center"
                                justifyContent="space-evenly"
                                //   minHeight="10vh"
                                width="100%"
                                fontSize="15px"
                            >
                                <Grid container spacing={12}>
                                    <Grid container spacing={3}>
                                        <Text p="10px">
                                            Venue: <span style={{ fontWeight: 700 }}>{successData.hotel_name}</span>
                                        </Text> </Grid>
                                    <Grid container spacing={3}>
                                        <Text p="10px">
                                            Room Type:{" "}
                                            <span style={{ fontWeight: 700 }}>{successData.room_name}</span>
                                        </Text>
                                    </Grid>
                                </Grid>
                            </Flex>
                        </Box>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minHeight="10vh"
                    width="100%"
                >
                    <a href="/" style={{ textDecoration: "none" }}>
                        <Button
                            width={"200px"}
                            bg={"rgba(21, 71, 142, 1)"}
                            color={"white"}
                            _hover={{
                                bg: "rgba(21, 71, 142, 1)",
                            }}
                            type="submit"
                            borderRadius="30px"
                        >
                            Done
                        </Button>
                    </a>
                    <Button
                        width={"200px"}
                        bg={"rgba(21, 71, 142, 1)"}
                        color={"white"}
                        _hover={{
                            bg: "rgba(21, 71, 142, 1)",
                        }}
                        type="submit"
                        borderRadius="30px"
                        margin="5px"
                        onClick={() => {
                            window.location.href = `/paymentreceipt/${personid}`;
                        }}
                    >
                        Download Receipt
                    </Button>


                    {isError && (
                        <Alert status="error">
                            <AlertIcon />
                            <AlertTitle>Wrong Credentials</AlertTitle>
                            <AlertDescription>
                                Please Add Correct Email And Password
                            </AlertDescription>
                        </Alert>
                    )}
                </Box>
            </Stack>

            <Footer />
        </>
    )
}

export default Successfullpay