import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Text,
  Spacer,
} from "@chakra-ui/react";
import React from "react";
import Logo from "../assets/Logo.png";
import whiteLogo from "../assets/footerLogo.jpeg";
import {
  FaFacebookF,
  FaInstagram,
  FaPinterestSquare,
  FaTwitterSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <>
      <div className="header footer">
        <div className="">
          <GridItem h="10">{/* <Image src={whiteLogo} /> */}</GridItem>
        </div>
        <div className="right-part">
          <Flex justifyContent="space-between" color="white">
            <div className="left-part1">
              <GridItem h="10">
                <Image src={whiteLogo} />
              </GridItem>
            </div>
            {/*  <a href="https://www.rotary.org/en" target="_blank" rel="noopener noreferrer">
              <Text fontSize="m">Rotary Org</Text>
            </a>
}
            {/* <Link to="/about" p="10px">
              <Text fontSize="m">End Polio Now</Text>
            </Link>
            <Link to="/about" p="10px">
              <Text fontSize="m">Careers</Text>
            </Link> */}
          </Flex>
        </div>
      </div>
      <Flex flexDirection="column">
        <Box p="4">
          <Box>
            <Flex>
              {/*     <Link m="0px 10px" href="">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ color: "white" }}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <g transform="matrix(0.963373,0,0,1,-14.4506,-15)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20.006 17.3093C19.9771 15.9985 19.0397 15 17.5174 15C15.9952 15 15 15.9985 15 17.3093C15 18.5931 15.9658 19.6203 17.4597 19.6203H17.4881C19.0397 19.6203 20.006 18.5931 20.006 17.3093ZM19.7128 21.4451H15.2631V34.8148H19.7128V21.4451ZM30.6371 21.1312C33.5652 21.1312 35.7604 23.0425 35.7604 27.149L35.7602 34.8148H31.3107V27.662C31.3107 25.8654 30.6667 24.6394 29.0559 24.6394C27.8266 24.6394 27.0943 25.4659 26.7727 26.2642C26.6551 26.5503 26.6262 26.9489 26.6262 27.3484V34.8152H22.1759C22.1759 34.8152 22.2346 22.7 22.1759 21.4455H26.6262V23.3392C27.2168 22.4289 28.2745 21.1312 30.6371 21.1312Z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
              </Link>

              <Link m="0px 10px" href="">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  style={{ color: "white" }}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="matrix(1,0,0,1,-15,-14.9)">
                    <path
                      d="M20 21.5225H22.0641V19.541C22.0641 18.6328 22.0641 17.3118 22.7246 16.4861C23.3851 15.5779 24.3758 15 25.9445 15C28.504 15 29.5773 15.3303 29.5773 15.3303L29.0819 18.3851C29.0819 18.3851 28.2563 18.1374 27.4307 18.1374C26.6051 18.1374 25.9445 18.3851 25.9445 19.2107V21.5225H29.2471L28.9994 24.4948H25.9445V34.8152H22.0641V24.4948H20V21.5225Z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
              </Link>
              <Link m="0px 10px" href="">
                <svg
                  width="26"
                  height="18"
                  viewBox="0 0 26 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.2344 2.85938C24.9531 1.73438 24.0625 0.84375 22.9844 0.5625C20.9688 0 13 0 13 0C13 0 4.98438 0 2.96875 0.5625C1.89062 0.84375 1 1.73438 0.71875 2.85938C0.15625 4.82812 0.15625 9.04688 0.15625 9.04688C0.15625 9.04688 0.15625 13.2188 0.71875 15.2344C1 16.3594 1.89062 17.2031 2.96875 17.4844C4.98438 18 13 18 13 18C13 18 20.9688 18 22.9844 17.4844C24.0625 17.2031 24.9531 16.3594 25.2344 15.2344C25.7969 13.2188 25.7969 9.04688 25.7969 9.04688C25.7969 9.04688 25.7969 4.82812 25.2344 2.85938ZM10.375 12.8438V5.25L17.0312 9.04688L10.375 12.8438Z"
                    fill="white"
                  />
                </svg>
              </Link>
              <Link m="0px 10px" href="">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <g transform="matrix(1,0,0,1,-14.9,-14.8984)">
                    <path
                      d="M34.7326 18.7974C33.9895 19.1276 33.2465 19.3753 32.4208 19.4579C33.2465 18.9625 33.907 18.1369 34.2372 17.2287C33.4116 17.7241 32.586 18.0543 31.6778 18.2194C30.9347 17.3938 29.8614 16.8984 28.7055 16.8984C26.4763 16.8984 24.6599 18.7148 24.6599 20.944C24.6599 21.2743 24.6599 21.6045 24.7425 21.8522C21.2748 21.6871 18.3025 20.0358 16.321 17.5589C15.9082 18.2194 15.7431 18.88 15.7431 19.623C15.7431 21.0266 16.4861 22.265 17.5595 23.0081C16.899 23.0081 16.2384 22.843 15.7431 22.5127V22.5953C15.7431 24.5768 17.1466 26.2281 19.0456 26.5583C18.7153 26.6409 18.3851 26.7235 17.9723 26.7235C17.7246 26.7235 17.4769 26.7235 17.2292 26.6409C17.7246 28.2922 19.2107 29.448 21.0271 29.448C19.6235 30.5214 17.8897 31.1819 15.9908 31.1819C15.6605 31.1819 15.3303 31.1819 15 31.0993C16.8164 32.2552 18.963 32.9157 21.1922 32.9157C28.7055 32.9157 32.7511 26.7235 32.7511 21.3568C32.7511 21.1917 32.7511 21.0266 32.7511 20.8615C33.4942 20.2835 34.1547 19.5405 34.7326 18.7974Z"
                      fill="white"
                    />
                  </g>
                </svg>
          </Link>*/}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export default Footer;
