import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Container, Typography, ThemeProvider, createTheme } from '@mui/material';
import { TextField, InputAdornment, IconButton, MenuItem, Grid, Button } from '@mui/material';
import { Search } from '@mui/icons-material';
import "../../../../node_modules/primereact/resources/themes/lara-light-indigo/theme.css";
import "../../../../node_modules/primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventRegistration from '../../../pages/EventRegistration'
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';


import 'jspdf-autotable';
import * as XLSX from 'xlsx';



const theme = createTheme();

const AdminProductShow = () => {
  let navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [selectedClub, setSelectedClub] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [hotelData, setHotelData] = useState([]);
  const [selectedBookingDate, setSelectedBookingDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [travelModes, setTravelModes] = useState([]);
  const [selectedTravelMode, setSelectedTravelMode] = useState('');
  const [selectedFoodPreference, setSelectedFoodPreference] = useState('');
  const [pdfData, setPdfData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [exportType, setExportType] = useState(null);

  const apiBase = process.env.REACT_APP_MAIN_URL;

  useEffect(() => {
    axios.get(`${apiBase}api/auth/getResponseList`)
      .then((response) => {
        setUserList(response.data.data);
        setFilteredUserList(response.data.data);
        setDataLoaded(true);
        setTravelModes(response.data.data)
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });

    axios.get(`${apiBase}api/auth/getRoteryClubData`)
      .then((response) => {
        setClubs(response.data.data);
      })
      .catch((error) => {
        console.error('Error fetching club data:', error);
      });

    axios.get(`${apiBase}api/auth/getHotelData`)
      .then((response) => {
        setHotelData(response.data.data);
      })
      .catch((error) => {
        console.log("Error fetching hotel data", error);
      });
  }, []);


  useEffect(() => {
    // Your existing code...

    // Set pdfData and excelData with the filteredUserList
    setPdfData(filteredUserList);
    setExcelData(filteredUserList);
  }, [filteredUserList]);


  const handleFilterChange = (filterKey, value) => {
    try {
      const newFilteredList = userList.filter(user => {
        const fieldValue = user[filterKey] ? user[filterKey].toString().toLowerCase() : '';
        return fieldValue.includes(value.toLowerCase());
      });
      setFilteredUserList(newFilteredList);
    } catch (error) {
      console.error('Error applying filter:', error);
    }
  };

  const handleTravelModeFilter = (value) => {
    setSelectedTravelMode(value);
    const newFilteredList = userList.filter(user => {
      const mode = user['travel_mode'] ? user['travel_mode'].toString().toLowerCase() : '';
      return mode.includes(value.toLowerCase());
    });
    setFilteredUserList(newFilteredList);
  };

  const handleBookingDateFilter = (value) => {
    setSelectedBookingDate(value);
    const newFilteredList = userList.filter(user => {
      const bookingDate = user['StartDate'] ? user['StartDate'].toString().toLowerCase() : '';
      return bookingDate.includes(value.toLowerCase());
    });
    setFilteredUserList(newFilteredList);
  };

  const handleEndDateFilter = (value) => {
    setSelectedEndDate(value);
    const newFilteredList = userList.filter(user => {
      const endDate = user['EndDate'] ? user['EndDate'].toString().toLowerCase() : '';
      return endDate.includes(value.toLowerCase());
    });
    setFilteredUserList(newFilteredList);
  };

  const handleFoodPreferenceFilter = (value) => {
    setSelectedFoodPreference(value);
    const newFilteredList = userList.filter(user => {
      const foodPreference = user['food_prefrence'] ? user['food_prefrence'].toString().toLowerCase() : '';
      return foodPreference.includes(value.toLowerCase());
    });
    setFilteredUserList(newFilteredList);
  };

  const generatePdf = () => {
    setExportType('pdf');
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: [297, 210],
    });

    // Define custom column widths for all columns (adjust as needed)
    const customColumnWidths = [
      10,    // Booking No
      18,    // Booking Time
      17,    // Name
      14,    // Mobile
      10,    // Total Person
      14,    // Spouse Name
      10,    // Child Count
      19,    // Club Name
      16,    // Food Preference
      13,    // Mocktail
      15,    // Hotel 
      20,    // Room Type 
      15,    // Amount
      20,    // Payment Mode
      18,    // Transaction ID
      20,    // Food Preference
      20,    // Travel Mode
      15,    // Note
      20,    // Created by
    ];


    const tableData = pdfData.map(item => columns.map(column => item[column.selector]));


    doc.autoTable({
      head: [columns.map(column => column.name)],
      body: tableData,
      columnStyles: customColumnWidths.map(width => ({ columnWidth: width })),

    });
    doc.save('RegistrationList.pdf');
  };



  const generateExcel = () => {
    const modifiedUserList = userList.map(item => ({
      'Booking No': item.response_number,
      'Booking Time': item.booking_time,
      'Name': item.member_name,
      'Mobile': item.mobile,
      'Total Person': item.totalPerson,
      'Spouse Name': item.SpouseName,
      'Child count': item.isChildAdd,
      'Club Name': item.club_name,
      'Food Preference': item.food_prefrence,
      'Mocktail': item.isMocktail,
      'Hotel': item.hotel_name,
      'Room Type': item.room_name,
      'Amount': item.Amount,
      'Payment Mode': item.payment_mode,
      'Transaction ID': item.transaction_id,
      'Travel Mode': item.travel_mode,
      'Note': item.payment_note,
      'Created by': item.CreatedBy,
    }));

    const ws = XLSX.utils.json_to_sheet(modifiedUserList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'RegistrationList.xlsx');
  };


  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleAction(rowData)}
      >
        <ReceiptLongIcon />
      </Button>
    );
  };

  const handleAction = (rowData) => {
    // Get the ResponseId from the rowData
    const responseId = rowData.ResponseId;
    // Construct the URL with the ResponseId
    const url = `/paymentreceipt/${responseId}`;
    // Open the URL in a new tab
    window.open(url, '_blank');
  };
  const columns = [
    { name: 'Booking No', selector: 'response_number', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Booking Time', selector: 'booking_time', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Name', selector: 'member_name', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Mobile', selector: 'mobile', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Total Person', selector: 'totalPerson', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Spouse Name', selector: 'SpouseName', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Child Count', selector: 'isChildAdd', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Club Name', selector: 'club_name', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Food Preference', selector: 'food_prefrence', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Mocktail', selector: 'isMocktail', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Hotel', selector: 'hotel_name', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Room Type', selector: 'room_name', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Amount', selector: 'Amount', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Payment Mode', selector: 'payment_mode', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Transaction ID', selector: 'transaction_id', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Travel Mode', selector: 'travel_mode', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Note', selector: 'payment_note', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Created by', selector: 'CreatedBy', sortable: true, filter: { type: 'text', matchMode: 'contains' } },
    { name: 'Download Receipt', header: 'Action', selector: actionBodyTemplate, },

  ];

  const handleAdminButtonClick = () => {
    const variableToSend = "1";
    const adminId = localStorage.getItem("Admin_Id");
    console.log("Retrieved AdminId from localStorage:", adminId);


    navigate('/', {
      state: {
        Isadmin: variableToSend,
        AdminId: adminId,
      }
    })
  }


  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom>
              Registration List
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleAdminButtonClick}>
              Add New Registration
            </Button>
            <span style={{ margin: '0 8px' }}></span>
            <Button variant="contained" color="primary" onClick={generatePdf}>
              Generate PDF
            </Button>
            <span style={{ margin: '0 8px' }}></span>
            <Button variant="contained" color="primary" onClick={generateExcel}>
              Generate Excel
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <h2 style={{ color: "rgb(47, 133, 90)" }}>Advance Filter ↴</h2>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      style={{ marginBottom: "12px" }}
                      label="Global Filter"
                      fullWidth
                      onChange={event => {
                        const filterValue = event.target.value.toLowerCase();
                        const newFilteredList = userList.filter(user => {
                          const bookingNumber = user['response_number'] ? user['response_number'].toString().toLowerCase() : '';
                          const mobile = user['mobile'] ? user['mobile'].toString().toLowerCase() : '';
                          const name = user['member_name'] ? user['member_name'].toString().toLowerCase() : '';
                          const clubName = user['club_name'] ? user['club_name'].toString().toLowerCase() : '';
                          const hotel = user['hotel_name'] ? user['hotel_name'].toString().toLowerCase() : '';
                          const spouseName = user['SpouseName'] ? user['SpouseName'].toString().toLowerCase() : '';
                          const roomType = user['room_name'] ? user['room_name'].toString().toLowerCase() : '';
                          const amount = user['Amount'] ? user['Amount'].toString().toLowerCase() : '';
                          const paymentMode = user['payment_mode'] ? user['payment_mode'].toString().toLowerCase() : '';
                          const createdby = user['CreatedBy'] ? user['CreatedBy'].toString().toLowerCase() : '';
                          const note = user['payment_note'] ? user['payment_note'].toString().toLowerCase() : '';
                          const travelMode = user['travel_mode'] ? user['travel_mode'].toString().toLowerCase() : '';
                          const foodPreference = user['food_prefrence'] ? user['food_prefrence'].toString().toLowerCase() : '';
                          const transactionID = user['transaction_id'] ? user['transaction_id'].toString().toLowerCase() : '';

                          const isMatched = (
                            bookingNumber.includes(filterValue) ||
                            name.includes(filterValue) ||
                            mobile.includes(filterValue) ||
                            clubName.includes(filterValue) ||
                            hotel.includes(filterValue) ||
                            spouseName.includes(filterValue) ||
                            roomType.includes(filterValue) ||
                            amount.includes(filterValue) ||
                            paymentMode.includes(filterValue) ||
                            createdby.includes(filterValue) ||
                            note.includes(filterValue) ||
                            travelMode.includes(filterValue) ||
                            foodPreference.includes(filterValue) ||
                            transactionID.includes(filterValue)
                          );

                          return isMatched;
                        });
                        setFilteredUserList(newFilteredList);
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      select
                      fullWidth
                      style={{ marginBottom: "12px" }}
                      label="Enter Club Name"
                      value={selectedClub}
                      onChange={event => setSelectedClub(event.target.value)}
                    >
                      {clubs.map(club => (
                        <MenuItem key={club.id} value={club.id}>
                          {club.RotaryClubName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Enter Booking Date"
                      fullWidth
                      style={{ marginBottom: "12px" }}
                      value={selectedBookingDate}
                      onChange={event => {
                        setSelectedBookingDate(event.target.value);
                        handleBookingDateFilter(event.target.value);
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      label="Filter by End Date"
                      fullWidth
                      style={{ marginBottom: "12px" }}
                      value={selectedEndDate}
                      onChange={event => {
                        setSelectedEndDate(event.target.value);
                        handleEndDateFilter(event.target.value);
                      }}
                    />
                    </Grid> */}
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      select
                      fullWidth
                      style={{ marginBottom: "12px" }}
                      label="Select Travel Mode"
                      value={selectedTravelMode}
                      onChange={event => {
                        setSelectedTravelMode(event.target.value);
                        handleTravelModeFilter(event.target.value);
                      }}
                    >
                      {Array.from(new Set(travelModes.map(mode => mode.travel_mode))).map(uniqueMode => (
                        <MenuItem key={uniqueMode} value={uniqueMode}>
                          {uniqueMode}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>


                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      size="small"
                      variant="outlined"
                      select
                      fullWidth
                      style={{ marginBottom: "12px" }}
                      label="Select Food Preference"
                      value={selectedFoodPreference}
                      onChange={event => {
                        setSelectedFoodPreference(event.target.value);
                        handleFoodPreferenceFilter(event.target.value);
                      }}
                    >

                      {Array.from(new Set(userList.map(user => user.food_prefrence))).map(uniquePref => (
                        <MenuItem key={uniquePref} value={uniquePref}>
                          {uniquePref}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item xs={12}>
            {dataLoaded && (
              <DataTable
                value={filteredUserList}
                scrollable
                scrollHeight="600px"
                paginator
                rows={10}
                totalRecords={filteredUserList ? filteredUserList.length : 0}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rowsPerPageOptions={[10, 20, 50]}
                emptyMessage="No records found"
                filterDisplay="row"
                className="p-datatable-striped p-datatable-gridlines"
                filterBy={[
                  ...columns.map(column => ({ field: column.selector, filterMatchMode: 'contains' })),
                  { field: 'StartDate', filterMatchMode: 'contains' },
                  { field: 'EndDate', filterMatchMode: 'contains' }
                ]}
              >
                {columns.map(column => (
                  <Column
                    key={column.selector}
                    field={column.selector}
                    header={column.name}
                    sortable={column.sortable}
                    filter={column.filter}
                    filterElement={
                      column.filter && (
                        <TextField
                          size="small"
                          variant="outlined"
                          placeholder={`Filter by ${column.name}`}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <IconButton disabled>
                                  <Search />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          onChange={event => handleFilterChange(column.selector, event.target.value)}
                        />
                      )
                    }
                  />
                ))}
              </DataTable>
            )}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );

};

export default AdminProductShow;
