import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Input, Button } from '@chakra-ui/react';

function PopupForOtp() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setPhoneNumber('');
    setOtp('');
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    // Only allow numeric characters and limit to 10 digits
    if (/^[0-9]*$/.test(inputValue) && inputValue.length <= 10) {
      setPhoneNumber(inputValue);
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = () => {
    // Here you can handle the submission of the phone number and OTP.
    // You can make API calls, perform validation, etc.
    // After that, you can close the popup.
    closePopup();
  };

  return (
    <div>
      <Link style={{ padding: "5px" }} to="#" onClick={openPopup}>
        <Text fontSize="xl">Get Registration Receipt</Text>
      </Link>
      <Modal isOpen={isPopupOpen} onClose={closePopup}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enter Phone Number and OTP</ModalHeader>
          <ModalBody>
            <Input
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              mb="3"
            />
            <Input
              placeholder="OTP"
              value={otp}
              onChange={handleOtpChange}
              mb="3"
            />
            <Button colorScheme="blue" onClick={handleSubmit}>Submit</Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default PopupForOtp;
