import Navbar from '../componets/Navbar'
import Footer from '../componets/Footer'
import React, { useState,useEffect } from 'react';
import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Button,
    Flex,
    Stack,
    Text,
    Image,
    GridItem,

} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import Group36877 from "../assets/Group 36877.png";
import { useParams } from "react-router-dom";
import axios from 'axios';
const CancelPayment = () => {
    const personid= window.location.pathname.split('/').slice(-1)[0];
    const { isAuth, isError, isErrorMsg, isActive, isLoading } = useSelector(store => store.userSignup);
    const dispatch = useDispatch();
    const { uuid } = useParams();
    const apiBase = process.env.REACT_APP_MAIN_URL;
    const [successData, setSuccessData] = useState([])


    const initialFormState = {
        member_name: "",
        gender: "",
        mobile_number: "",
        email: "",
        rotary_club: "",
        hotel: "",
        room_type1: "",
        room_type2: "",
        food_preference: "",
        transportation: "",
        registration_type: ""
    };
    useEffect(() => {
        debugger;
        const apiData = {
            ResponseId: personid
        };
        axios
            .post(`${apiBase}api/payment/getPaymentReceiptData`, apiData)
            .then((response) => {
                setSuccessData(response.data.data[0]);
            })
            .catch((error) => {
                // Handle the error if needed
            });
    }, []);




    return (
        <>
            <Navbar />


            <div>
                <Flex
                    minH={"100vh"}
                    align={"center"}
                    justify={"center"}
                >
                    <Stack
                        spacing={69}
                        w={"full"}
                        maxW={"3xl"}
                        rounded={"xl"}
                        boxShadow={"xl"}
                        p={5}
                        my={12}
                        alignItems={"center"}
                        style={{
                            background: "rgba(247, 167, 29, 0.10)",
                            borderRadius: "10px",
                        }}
                    >
                        <GridItem w="150px" h="10" justifySelf={{ base: "center", md: "left" }}>
                            <Image src={Group36877} />
                        </GridItem>
                        <Text textAlign="center" lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }} color="rgba(21, 71, 142, 1)" style={{ fontFamily: "Montserrat", fontSize: "55.27px" }}>

                        </Text>
                        <Text textAlign="center" lineHeight={1.1} fontSize={{ base: "sm", md: "sm" }} color="rgba(21, 71, 142, 1)" style={{ fontFamily: "Montserrat", fontSize: "55.27px" }}>
                            Payment Cancel
                        </Text>
                        <a href="/" style={{ textDecoration: 'none' }}>
                            <Button
                                width={"200px"}
                                bg={"rgba(21, 71, 142, 1)"}
                                color={"white"}
                                _hover={{
                                    bg: "rgba(21, 71, 142, 1)",
                                }}
                                type="submit"
                                borderRadius="30px"
                            >
                                Retry
                            </Button>
                        </a>
                    </Stack>
                </Flex>
                {isError && (
                    <Alert status='error'>
                        <AlertIcon />
                        <AlertTitle>Wrong Credentials</AlertTitle>
                        <AlertDescription>Please Add Correct Email And Password</AlertDescription>
                    </Alert>
                )}
            </div>
            <Footer />
        </>
    )
}

export default CancelPayment;