import React from 'react'
import HomeBottom from './HomeBottom'
import HomeMiddle from './HomeMiddle'
import HomeTop from './HomeTop'
import HomeWork from './HomeWork'
import Homebottom2 from "./Homebottom2"
import HomeLower from './HomeLower'
import HomeLast from './HomeLast'
import Navbar from '../../componets/Navbar'
import Footer from '../../componets/Footer'
import EventRegistration from '../EventRegistration'

const Home = () => {
  return (
    <>
      <Navbar />
   {/*   <HomeTop/>*/}
      <EventRegistration/>
      <Footer/>
    </>
  )
}

export default Home