import React from "react";
import { Route, Routes, useParams, useNavigate } from "react-router-dom";
import AdminRequireAuth from "../hoc/AdminRequireAuth";
import AdminHome from "./AdminPages/AdminHome";
import AdminLogin from "./AdminPages/AdminLogin";

import Login from "./Login";
import EventRegistration from "./EventRegistration";
import Home from "./home/Form_1";
import AdminProduct from "./AdminPages/AdminProduct";
import AdminUser from "./AdminPages/AdminUser";
import SuccessPayment from "./SuccessPayment";
import OtpVerification from "./OtpVerification";
import FailedPayment from "./FailedPayment";
import CancelPayment from "./CancelPayment";
import PaymentReceipt  from "./PaymentReceipt";
import PendingPayment from "./PendingPayment";
import Payment from "./Payment";
import AdminAddProduct from "./AdminPages/AdminAddProduct";



const AllRoutes = () => {
  // function PaymentStatus() {
  //   debugger;
  //   const { status, uuid } = useParams();
  //   const navigate = useNavigate();

  //   if (status === "success") {
  //     navigate(`/success/${uuid}`);
  //   } else if (status === "failed") {
  //     navigate(`/failed/${uuid}`);
  //   } else if (status === "cancelled") {
  //     navigate(`/cancelled/${uuid}`);
  //   } else {
  //     return <div>Invalid payment status</div>;
  //   }
  // }

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
       
        <Route path="/signup" element={<EventRegistration />} />
        <Route path="/paymentreceipt/:userId" element={<PaymentReceipt />} />
        <Route path="/login" element={<Login />} />
        <Route path="/getregistrationteceipt" element={<OtpVerification/>} />
        <Route path="/payment/:userId" element={<Payment />} />
        <Route path="/payment/Success/:userId" element={<SuccessPayment />} />
        <Route path="/payment/Pending/:userId" element={<PendingPayment />} />
        <Route path="/payment/Failed/:userId" element={<FailedPayment />} />
        <Route path="/payment/Cancelled/:userId" element={<CancelPayment />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin" element={ <AdminRequireAuth><AdminProduct /> </AdminRequireAuth>}></Route>
        <Route path="/admin/user" element={<AdminRequireAuth><AdminUser /></AdminRequireAuth>}></Route>
        <Route path="/admin/addProduct" element={ <AdminRequireAuth> <AdminAddProduct /> </AdminRequireAuth>}></Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
